
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import CreateEditDialog from '@/components/dialog/CreateEditDialog.vue';
import UserForm from '@/components/user/UserForm.vue';
import useUser from '@/components/user/user';

export default defineComponent({
  name: 'CreateEditUserDialog',
  components: {
    CreateEditDialog,
    UserForm,
  },
  setup() {
    // store
    const store = useStore();

    return {
      ...useUser(store),
    };
  },
});
