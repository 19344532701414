
import {defineComponent} from 'vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useUserList from './userList';
import CreateEditUserDialog from '@/components/user/CreateEditUserDialog.vue';

export default defineComponent({
  name: 'UserList',
  components: {
    ListLayout,
    CreateEditUserDialog,
  },
  props: {
    noActions: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
      selectableFunction,
    } = useUserList();

    return {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
      selectableFunction,
    };
  },
});
